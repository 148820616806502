.btn-acao-amostra {
    width: 24% !important;
    padding: 5px;
    margin-right: 10px;
}

.bg-gradient-info{
    background: linear-gradient(87deg, #336666 0, #336666 100%) !important;
    padding-bottom: 4rem !important;
}

.bg-gradient-info-arbotech{
    background: linear-gradient(87deg, #D71921 0, #D71921 100%) !important;    
    padding-bottom: 4rem !important;
}

.pt-md-8, .py-md-8 {
    padding-top: 4rem !important;
}

.btn-primary {
    color: #fff;
    background-color: #9eb833;
    border-color: #9eb833;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary-arbotech {
    color: #fff;
    background-color: #8e8c8d;
    border-color: #8e8c8d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary-arbotech:hover {
    color: #fff;
    background-color: #bdbbbc;
    border-color: #bdbbbc; 
}

.text-primary, .text-orange, .text-blue, .text-yellow, .text-red, .text-info, .btn-icon-clipboard i {
    color: #9eb833 !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #336666;
    border-color: #336666; 
}

.btn-default, .btn-default:hover {
    color: #fff;
    background-color: #f1773d;
    border-color: #f1773d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bg-gradient-info {
    background: linear-gradient(87deg, #336666 0, #336666 100%) !important;
}

.bg-default {
    background-color: #9eb833 !important;
}

.bg-default-arbotech {
    background-color: #232020 !important;
}

.fill-default {
    fill: #9eb833 !important;
}

.fill-default-arbotech {
    fill: #232020 !important;
}

.leaflet-container {
    height: 400px;
    width: 100%;
    margin: "30px 0px 0px 0px";
}

.bnt-voltar{
    font-size: 30px;
    top: 10px;
    position: relative;
    z-index: 9;
    color: #9eb833; 
}
 .sr-only {
    display: none;
  }