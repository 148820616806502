.btn-acao-amostra {
    width: 24% !important;
    padding: 5px;
    margin-right: 10px;
}

.bg-gradient-info{
    background: linear-gradient(87deg, #0D4B66 0, #0D4B66 100%) !important;
    padding-bottom: 4rem !important;
}

.pt-md-8, .py-md-8 {
    padding-top: 4rem !important;
}

.btn-primary {
    color: #fff;
    background-color: #1EAAE6;
    border-color:#1EAAE6;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.text-primary, .text-orange, .text-blue, .text-yellow, .text-red, .text-info, .btn-icon-clipboard i {
    color: #285366 !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #1784B3;
    border-color: #1784B3; 
}

.btn-default, .btn-default:hover {
    color: #fff;
    background-color: #f1773d;
    border-color: #f1773d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bg-gradient-info {
    background: linear-gradient(87deg, #0D4B66 0, #0D4B66 100%) !important;
}

.bg-default {
    background-color: #1EAAE6 !important;
}
.fill-default {
    fill: #1EAAE6 !important;
}

.leaflet-container {
    height: 400px;
    width: 100%;
    margin: "30px 0px 0px 0px";
}

.bnt-voltar{
    font-size: 30px;
    top: 10px;
    position: relative;
    z-index: 9;
    color: #1784B3; 
}
 .sr-only {
    display: none;
  }