@media only screen and (max-width: 910px){
    .responsive-filter-field-inventoryMovement-full{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-filter-field-inventoryMovement-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-form-field-inventoryMovement-full{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-form-field-inventoryMovement-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}

@media only screen and (max-width:290px){
    .responsive-filter-field-inventoryMovement-medium{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-form-field-inventoryMovement-medium{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}

.center-div{
    display: flex;
    justify-content: center;
    align-items: center;
}