.input_coluna{
    width: 100%;
    background-color: transparent;
    border: 0;
}

.btn_form {
    background-color: #4f8bb3;
    border: 0.3rem solid transparent;
    margin: 1rem 1rem  0 0;
    color: #ffffff;
    border-radius: 0.4rem;
}

.btn_bars {
    background-color: transparent;
    border: 0;
}

.btn_card {
    padding-top: 1rem;
    border: 0px;
    border-radius: .7rem;
    color: #ffffff;
}

.btn_card:hover {
    cursor: pointer;
}

.vertical-header {
    writing-mode: vertical-rl; 
    transform: rotate(-180deg);
}

.align-left {
    text-align: left;
}

.table_form_vistoria th,
.table_form_vistoria td {
    border: 0.1rem solid black;
    padding: 5px;

}