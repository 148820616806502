.tab-container{
    padding:0px !important;
    margin:0px !important;
}

.tab-item{
    max-width:30% !important;
}

.filter-btn-int {
    width: 20% !important;
    max-width: 100% !important;
    margin: 0 !important;
}


@media only screen and (max-width:910px) {
    .tab-item{
        max-width:47% !important;
    }
}