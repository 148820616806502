@media only screen and (max-width: 910px){
    .container-flex-result{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        padding:0;
    } 
    .responsive-card-result{
        padding:6px 3px 6px 3px;
    }
    .responsive-button-result{
        white-space:normal
    }
    .responsive-form-field-result-full{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
        word-wrap: break-word;
    }
    .responsive-form-field-result-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-top:5px;
        padding-bottom:5px;
        word-wrap: break-word;
    }
}

@media only screen and (max-width:290px){
    .responsive-form-field-result-medium{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
        word-wrap: break-word;
    }
}