@font-face {
  font-family: "Cufon";
  src: url("../../../assets/fonts/cufon/Convexb.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.etiqueta p,
.etiqueta span {
  font-family: "Cufon" !important;
  color: rgb(62, 58, 58) !important;
}
