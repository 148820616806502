.uploadButton {
  padding: 10px;
  width: 100%;
  height: 200px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 2px dashed #C4C5C5;
  margin-bottom: 1.5rem;
}

.chooseFileText {
  color: #3277F8;
}

.chooseFileText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.downloadModelButton:hover {
  cursor: pointer;
  color: black;
}