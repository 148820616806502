.programa-principal-container{
    width:100%;
}

.container-flex{
    width:100%;
    padding:0;
    display:flex;
    flex-wrap:wrap;
}

@media only screen and (max-width: 910px){
    .responsive-field-form-full{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-field-form-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-bottom:15px;
    }
    .responsive-form-programa-button{
        padding-top:15px;
    }
    .responsive-map-button{
        display:none;
    }
}

@media only screen and (max-width:290px){
    .responsive-field-form-medium{
        flex:1 !important;
        min-width:100% !important;
        padding-bottom:15px;
    }
}