@media only screen and (max-width: 910px){
    .container-flex-offlineContingency{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        padding:0;
    } 
    .responsive-form-field-offlineContingency-full{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-form-field-offlineContingency-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}

@media only screen and (max-width:290px){
    .responsive-form-field-offlineContingency-medium{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}