[class^="css-"][class$="-menu"] {
    z-index: 9999999 !important;
}

.filter-container-sispncd{
    width:100% !important;
    display:flex !important;
    justify-content: space-between !important;
    padding:0px !important;
    margin:0px !important;
    max-width:100% !important;
}

.hide-btn-sispncd{
    max-width:30% !important;
    margin-bottom:2vh !important;
}
.filter-btn-sispncd{
    max-width: 20% !important;
    margin-bottom:2vh !important;
}
@media only screen and (max-width: 910px){
    .responsive-field-filter-sispncd-full{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-field-filter-sispncd-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-bottom:15px;
    }

    .hide-btn-sispncd{
        max-width:50% !important;
    }

    .filter-btn-sispncd{
        max-width:35% !important;
    }
}

@media only screen and (max-width:290px){
    .responsive-field-filter-sispncd-medium{
        flex:1 !important;
        min-width:100% !important;
        padding-bottom:15px;
    }

    .hide-btn-sispncd{
        max-width:100% !important;
    }

    .filter-btn-sispncd{
        max-width:100% !important;
        margin:0px 0px 2vh 0px !important;
    }
}