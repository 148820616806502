.container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: .5rem;
}

.actionsButton {
  margin: 0px !important;
  width: 150px;
}

.downloadButton {
  margin: 0px !important;
  width: 200px;
}

.downloadButtonIcon {
  width: 14px;
  height: 14px;
}

.label {
  font-weight: bold;
  width: 100%;
  display: flex;
  gap: .1rem;
}