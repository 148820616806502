dl, dd, dt{
    margin:0;
}
.table_specification{
    display: table;
    width:100%;
    border-spacing:5px 5px;
    border-collapse: collapse;
    font-size:.8125rem;
}
.table_specification dl{    
    display:table-row;  
}

.table_specification dd{
    display: table-cell;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding:5px;
}
@media only screen and (max-width: 910px){
    .container-flex-employee{
        width:100%;
        padding:0;
        display:flex;
        flex-wrap:wrap;
    }
    .table_specification dl{
        display:block;
        margin-bottom: 15px;
    }
    .table_specification dd{
        display: block;
        width:100%;
    } 
    .responsive-field-form-employee-full{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-field-form-employee-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-bottom:15px;
    }
}

@media only screen and (max-width:290px){
    .responsive-field-form-employee-medium{
        flex:1 !important;
        min-width:100% !important;
        padding-bottom:15px;
    }
}