.hr-modal{margin: 0.5rem 0 !important}

.title-edit-visit {
    color: #ffffff;
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 100px); 
    overflow-y: auto; 
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.styled-table th, 
.styled-table td {
    padding: 2px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.styled-table-header{
    font-size: small;
}

.styled-table-columns{
    font-size: small;
}

.styled-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.styled-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
    background-color: #ddd;
}

.fa-chevron-up,
.fa-chevron-down {
    transition: transform 0.3s ease;
}

.content-input{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}