.tableButtonPopover {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.tableButtonPopover:hover {
  background-color: #e6e6e6;
}