.row-gap{
    row-gap: 0.5rem;
}

@media only screen and (max-width: 910px){
    .responsive-filter-demanda{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-container-buttons{
        padding-top:10px;
    }
    .responsive-filter-button{
        flex:1 !important;
        max-width:50% !important;
    }
}

@media only screen and (max-width:290px){
    .responsive-filter-button{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
    }
}