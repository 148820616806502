
@media only screen and (max-width: 910px){
    .container-flex-team{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        padding:0px;
    }
    
    .responsive-field-form-team-full{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-field-form-team-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-bottom:15px;
    }
}

@media only screen and (max-width:290px){
    .responsive-field-form-team-medium{
        flex:1 !important;
        min-width:100% !important;
        padding-bottom:15px;
    }
}