.document--container--maintenance {
  background-color: white;
  font-size: 12px;
  line-height: 12px;
  padding: 10px;
}

.document__header--maintenance--image {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 16px;
}

.document__header--maintenance--image > p {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.document__header--maintenance--content {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-bottom: 16px;
}

.document__table,
.document__table--visit--verse {
  border-collapse: collapse;
  font-size: 10px;
  width: 100%;
}

.document__table--territorialization {
  width: 275px;
}

.document__table > thead > tr > th,
.document__table > tbody > tr > td {
  border: 1px solid black;
  padding: 6px;
}

.document__table--agents {
  width: 705px;
}

.document__table--date {
  width: 30px;
}

.document__table--visit > thead > tr > th,
.document__table--visit--verse > thead > tr > th {
  background-color: #4f8bb3;
  color: white;
  text-align: center;
}

.document__table--legend > thead > tr > th,
.document__table--legend > tbody > tr > td,
.document__table--visit--verse > thead > tr > th,
.document__table--visit--verse > tbody > tr > td {
  border: 1px solid black;
}

.document__table--legend > thead > tr > th {
  text-align: center;
}

.tbody__row > td {
  padding: 5px;
  height: 10px;
}

.vertical--header--maintenance {
  height: 100px;
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
  width: 20px;
}

.align-left {
  text-align: left;
}

.item {
  margin-right: 10px;
}

.numeration_column {
  width: 10px;
}

.detalhes {
  height: 10px;
}

@page {
  size: landscape;
}
