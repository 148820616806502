@media only screen and (max-width: 910px){
    .responsive-field-filter-territorializacao-full{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-button-territorializacao{
        white-space:normal;
    }
    .responsive-field-filter-territorializacao-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-bottom:15px;
    }
}

@media only screen and (max-width:290px){
    .responsive-field-filter-territorializacao-medium{
        flex:1 !important;
        min-width:100% !important;
        padding-bottom:15px;
    }
}