.instructions__table {
    border-collapse: collapse;
}

.instructions__row--thead {
    background-color: rgb(79, 139, 179);
}

.instructions__row, .instructions__cell {
    border: 1px solid black;
    text-align: center;
    padding: 5px;
}
.select:focus {
    border: 2px solid #007bff;
    outline: none;
    box-shadow: 0 0 5px #007bff;
}

.select {
    width: 50px
}

.instructions__row {
    background-color: lightgray;
}

.instructions__row:nth-child(odd){
    background-color: darkgray;
}