/* .IntegradoMap .leaflet-control-layers:nth-child(7) .leaflet-control-layers-toggle{
    background-image: url(https://media.istockphoto.com/vectors/traffic-cone-vector-sign-vector-id866159110?k=20&m=866159110&s=612x612&w=0&h=gu8e4G2UUZhfWtRGh068Fa488R9ShT-rAXQKY9s6aUs=);
    background-size: 40px;
}

.IntegradoMap .leaflet-control-layers:nth-child(6) .leaflet-control-layers-toggle{
    background-image: url(https://http2.mlstatic.com/adesivo-parede-mapa-brasil-estado-mapa-18-metro-decoraco-D_NQ_NP_712125-MLB25384525112_022017-F.jpg);
    background-size: 40px;
}

.IntegradoMap .leaflet-control-layers:nth-child(5) .leaflet-control-layers-toggle{
    background-image: url(https://th.bing.com/th/id/R.8cbb5e7bfa05345bd8101b58d368ad6f?rik=dFUrtrAo%2fStK%2bA&pid=ImgRaw&r=0);
    background-size: 40px;
}

.IntegradoMap .leaflet-control-layers:nth-child(1) .leaflet-control-layers-toggle{
    background-image: url(https://www.supersonic.ag/wp-content/uploads/2016/06/heatmap.png);
    background-size: 80px;
}

.IntegradoMap .leaflet-control-layers:nth-child(4) .leaflet-control-layers-toggle{
    background-image: url(https://static.vecteezy.com/ti/vetor-gratis/p1/6786109-plastico-construcao-capacete-cartoon-icone-ilustracao-vetor.jpg);
    background-size: 60px;
}

.IntegradoMap .leaflet-control-layers:nth-child(3) .leaflet-control-layers-toggle{
    background-image: url(https://st.depositphotos.com/1642482/1904/i/600/depositphotos_19049237-stock-photo-leaf.jpg);
    background-size: 40px;
}
.IntegradoMap .leaflet-control-layers:nth-child(2) .leaflet-control-layers-toggle{
    background-image: url(https://www.nicepng.com/png/full/189-1898604_sick-emoji-cara-de-enfermo-emoji.png);
    background-size: 40px;
} */

[class^="css-"][class$="-menu"] {
    z-index: 9999999 !important;
}

.btn-primary{
    border-radius: 6px;
}

.btn-filters{
    width: 31%;
    margin: .1rem !important;
}

.btn-selected{
    width: 31%;
    margin-right: 0px;
    background-color: #a2a2a2 !important;
    background-color: #5eb39f !important;
    
    color: rgb(0, 0, 0) !important;
    border: 5px solid rgb(0, 0, 0) !important;
}


.tab-container-int {
    width: 100%;
    text-align: center;
    padding: 0px !important;
    margin: 0px !important;
}

.tab-item {
    max-width: 30% !important;
}

.filter-btn-int {
    width: 20% !important;
    max-width: 100% !important;
    margin: 0 !important;
}


@media only screen and (max-width:910px) {
    .tab-item {
        max-width: 47% !important;
    }
}

.filter-container {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
}

.hide-btn {
    max-width: 30% !important;
    margin-bottom: 2vh !important;
}

.filter-btn {
    max-width: 20% !important;
    margin-bottom: 2vh !important;
}


@media only screen and (max-width:910px) {
    .responsive-field-sinan-full {
        flex: none !important;
        min-width: 100% !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .responsive-field-sinan-medium {
        flex: 1 !important;
        min-width: 50% !important;
        padding-bottom: 15px;
    }

    .hide-btn {
        max-width: 50% !important;
    }

    .filter-btn {
        max-width: 35% !important;
    }
}

@media only screen and (max-width: 330px) {
    .filter-container {
        flex-direction: column !important;
    }

    .responsive-field-sinan-medium {
        flex: 1 !important;
        min-width: 100% !important;
        padding-bottom: 15px;
    }

    .hide-btn {
        max-width: 100% !important;
    }

    .show-btn{
        max-width: 100% !important;
        margin: 0px 0px 2vh 0px !important;
    }

    
}
