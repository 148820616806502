/* UTILITY */

.bold {
  font-weight: bold;
}

/* UTILITY */

.document--container--install {
  background-color: #ffffff;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 12px;
  padding: 10px;
}

.document__header--install {
  margin-bottom: 10px;
}

.document__header--company--install {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 10px;
}

.document__header--company--install > p {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.document__header--data--install {
  display: flex;
  justify-content: space-between;
}

.document__table--employees--install {
  width: 675px;
}

.document__table--employees--install > tbody > tr > td > span {
  display: block;
}

.document__table--traps {
  border-collapse: collapse;
  width: 100%;
}

.document__table--traps > thead > tr {
  background-color: #4f8bb3;
  color: #ffffff;
  text-align: center;
}

.document__table--install > thead > tr > th,
.document__table--install > tbody > tr > td {
  border: 1px solid black;
  padding: 5px;
  vertical-align: middle;
}

.vertical-header {
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
}

@page {
  size: landscape;
}
