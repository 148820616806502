.customized-legend{
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-bottom {
    z-index: 0 !important;
}

.custom_zindex div{
    z-index: 9999;
}

.filter-container {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
}

.hide-btn {
    max-width: 30% !important;
    margin-bottom: 2vh !important;
}

.sinan-container [class^="css-"] {
    z-index: 9999 !important;
}

.filter-btn {
    max-width: 20% !important;
    margin-bottom: 2vh !important;
}


@media only screen and (max-width:910px) {
    .responsive-field-sinan-full {
        flex: none !important;
        min-width: 100% !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .responsive-field-sinan-medium {
        flex: 1 !important;
        min-width: 50% !important;
        padding-bottom: 15px;
    }

    .hide-btn {
        max-width: 50% !important;
    }

    .filter-btn {
        max-width: 35% !important;
    }
}

@media only screen and (max-width: 330px) {
    .filter-container {
        flex-direction: column !important;
    }

    .responsive-field-sinan-medium {
        flex: 1 !important;
        min-width: 100% !important;
        padding-bottom: 15px;
    }

    .hide-btn {
        max-width: 100% !important;
    }

    .filter-btn {
        max-width: 100% !important;
        margin: 0px 0px 2vh 0px !important;
    }
}
