@media only screen and (max-width: 910px){
    .responsive-form-field-sample-full{
        flex:none !important;
        min-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
    .responsive-form-field-sample-medium{
        flex:1 !important;
        min-width:50% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}

@media only screen and (max-width:290px){
    .responsive-form-field-sample-medium{
        flex:none !important;
        max-width:100% !important;
        padding-top:5px;
        padding-bottom:5px;
    }
}