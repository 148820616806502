.filter-container {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
}

.trap-container {
    display: flex;
    align-items: center; 
}

.trap-container .text-black {
    flex: 0 0 auto; 
    margin-right: 10px; 
}

.trap-container .form-control {
    flex: 1; 
}

.update-trap-input{
    display: inline-block;

}

.leaflet-control {
    z-index: 0 !important
}

.leaflet-pane {
    z-index: 0 !important
}

.hide-btn {
    max-width: 30% !important;
    margin-bottom: 2vh !important;
}

.filter-btn {
    max-width: 20% !important;
    margin-bottom: 2vh !important;
}

[class$="-multiValue"] {
    z-index: 0 !important;
}

@media only screen and (max-width:910px) {
    .hide-btn {
        max-width: 50% !important;
    }

    .filter-btn {
        max-width: 35% !important;
    }
}

@media only screen and (max-width: 330px) {
    .filter-container {
        flex-direction: column !important;
    }

    .hide-btn {
        max-width: 100% !important;
    }

    .filter-btn {
        max-width: 100% !important;
        margin: 0px 0px 2vh 0px !important;
    }
}
